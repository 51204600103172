import Proctoring from "./Proctoring";

import axios from "axios";
const axiosInstance = axios.create({
    timeout: 20000,
    withCredentials: true,
});

import getAPIDomain from "./domain";

export default class ScreenShareProctoring extends Proctoring {
    constructor(env) {
        super(env);
        this.screenShareStream = null;
        this.imageCaptureInterval = null;
    }

    async saveImageFromVideoCapture() {
        try {
            // Create a canvas element.

            this.imageCapture = new ImageCapture(this.videoTrack.clone());
            let bitmap = await this.imageCapture.grabFrame();
            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");

            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
            let img = canvas.toDataURL("image/png");

            axiosInstance
                .request({
                    method: "POST",
                    url:
                        getAPIDomain() +
                        `/app/api/interview/session-proctoring`,
                    data: {
                        img,
                        interviewId: this.interviewId,
                    },
                })
                .catch((err) => {
                    console.log("ERRR", err);
                });
        } catch (err) {
            console.log("ERRR", err);
        }
    }

    async setup({ updateOriginalState, interviewId, sessionId }) {
        try {
            this.interviewId = interviewId;
            this.sessionId = sessionId;
            const displayMediaOptions = {
                video: {
                    displaySurface: "monitor",
                    cursor: "never",
                },
                audio: false,
            };
            this.screenShareStream =
                await navigator.mediaDevices.getDisplayMedia(
                    displayMediaOptions
                );

            updateOriginalState({ isScreenShared: true });

            this.videoTrack = this.screenShareStream.getVideoTracks()[0];

            // Check if the display surface is a monitor (meaning the user has shared the entire screen)
            let trackSettings = this.videoTrack.getSettings();

            if (trackSettings.displaySurface !== "monitor") {
                // Tell the user that they need to share the entire screen and not just a window or tab
                this.videoTrack.stop();
                updateOriginalState({ isScreenShared: false });
                this.notifySubscribers({
                    isSharing: false,
                    wrongScreenShared: true,
                });
                this.stop();
                return;
            }

            this.videoTrack.onended = () => {
                console.log("Screen share ended");
                this.notifySubscribers({ isSharing: false });
                this.stop();
            };
            this.notifySubscribers({ isSharing: true });

            this.interval = setInterval(async () => {
                const currentFocus = document.hasFocus();
                const previousFocus = this.focus;
                if (this.firstTimeFocusValue) {
                    if (previousFocus !== currentFocus) {
                        // change in focus
                        this.focus = currentFocus;
                        this.notifySubscribers({ focus: this.focus });

                        if (!this.focus) {
                            this.timeout = setTimeout(async () => {
                                await this.saveImageFromVideoCapture();

                                this.imageCaptureInterval = setInterval(
                                    async () => {
                                        await this.saveImageFromVideoCapture();
                                    },
                                    10000
                                );
                            }, 1000);
                        } else {
                            // Stop capturing images
                            this.timeout && clearTimeout(this.timeout);
                            this.imageCaptureInterval &&
                                clearInterval(this.imageCaptureInterval);
                        }
                    }
                } else if (currentFocus) {
                    this.firstTimeFocusValue = true;
                    this.focus = currentFocus;
                    this.notifySubscribers({
                        focus: this.focus,
                        isFirstTimeFocus: true,
                    });
                }
            }, 300);
        } catch (err) {
            console.error("Screen share error", err);

            // Show permission denied error
            if (err.message === "Permission denied by system") {
                this.notifySubscribers({
                    isSharing: false,
                    permissionDenied: true,
                });
            }
            updateOriginalState({ isScreenShared: false });

            this.stop();
        }
    }

    subscribe(callback, updateOriginalState, interviewId, sessionId) {
        this.subscribers.push(callback);
        if (!this.interval || !this.screenShareStream) {
            this.stop();
            this.setup({ updateOriginalState, interviewId, sessionId });
        }
        return () => {
            this.subscribers.splice(this.subscribers.indexOf(callback), 1);
            if (this.subscribers.length === 0) {
                this.stop();
            }
        };
    }

    notifySubscribers({
        isSharing,
        outOfFocus = false,
        isFirstTimeFocus = false,
        wrongScreenShared = false,
        permissionDenied = false,
    }) {
        this.subscribers.map((x) =>
            x({
                isSharing,
                outOfFocus,
                isFirstTimeFocus,
                wrongScreenShared,
                permissionDenied,
            })
        );
    }

    isSharingEnabled() {
        return !!this.screenShareStream;
    }

    stop() {
        this.interval && clearInterval(this.interval);
        this.imageCaptureInterval && clearInterval(this.imageCaptureInterval);
        this.timeout && clearTimeout(this.timeout);
        this.screenShareStream?.getTracks()?.forEach((track) => track.stop());
        this.interval = null;
        this.imageCaptureInterval = null;
        this.timeout = null;
        this.screenShareStream = null;
        this.focus = null;
        this.videoTrack = null;
    }
}
